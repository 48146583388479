import React, { useState } from "react";

const Modal = ({ user, onClose, onEdit, onDelete }) => {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [main_balance, setMain_balance] = useState(user.main_balance);
  const [kyc_status, setKyc_status] = useState(user.kyc_status);
  const [amount, setAmount] = useState(user.amount);
  const [amount1, setAmount1] = useState(user.amount);
  const [nationality, setNationality] = useState(user.nationality);

  const handleSave = () => {
    onEdit({ ...user, name,amount, email, main_balance,kyc_status, nationality });
  };

  const balanx = parseFloat(amount1 || 0) || 0;
  const balanx2 = balanx.toLocaleString('en-US', {
  minimumFractionDigits: 2,maximumFractionDigits: 2});

  const balank = parseFloat(main_balance || 0) || 0;
  const balank2 = balank.toLocaleString('en-US', {
    minimumFractionDigits: 2,maximumFractionDigits: 2});

  return (
    <div style={styles.modal}>
          <div style={styles.modalContent}>
            <span style={styles.closeBtn} onClick={onClose}>
              &times;
            </span>
            <h2>FUND : {email} </h2>
            <hr></hr>
            <p style={styles.app} >
              <strong>Name:</strong> {name}
            </p>
            <p style={styles.app}>
              <strong>Email:</strong> {email}
            </p>

            <p style={styles.app}>
              <strong>Requested Deposit:</strong> {balanx2}
            </p>

            <hr></hr>
            <p style={styles.app}>
              <strong>
                user Balance:</strong> ${balank2}
            </p>
            
            <input
              type="text"
              value={amount}
              style={styles.input}
              onChange={(e) => setAmount(e.target.value)}
            />
            <hr></hr>
            <div style={styles.buttons}>
            <button style={styles.button} onClick={handleSave}>
              APPROVE
            </button>

            <button
            style={styles.deleteButton}
            onClick={() => onDelete(user)}
          >
            CANCEL
          </button>
          </div>
          </div>
        </div>
  );
};

export default Modal;

const styles = {
    app: {
      color: "black"
    },
    userList: {
      listStyle: "none",
      padding: 0,
      maxWidth: "400px",
      margin: "20px auto",
    },
    userItem: {
      padding: "10px",
      margin: "10px 0",
      background: "#f4f4f4",
      cursor: "pointer",
      borderRadius: "5px",
      transition: "background 0.3s",
    },
    userItemHover: {
      background: "#ddd",
    },
    modal: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContent: {
      background: "#fff",
      padding: "20px",
      borderRadius: "10px",
      width: "90%",
      maxWidth: "500px",
      textAlign: "left",
      position: "relative",
    },
    closeBtn: {
      position: "absolute",
      top: "10px",
      right: "10px",
      fontSize: "20px",
      fontWeight: "bold",
      cursor: "pointer",
      color: "#000",
    },
    closeBtnHover: {
      color: "red",
    },
    input: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    button: {
      padding: "10px 20px",
      background: "#007BFF",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
      },
    deleteButton: {
        backgroundColor: "#f44336",
        color: "white",
        border: "none",
        padding: "10px 20px",
        borderRadius: "5px",
        cursor: "pointer",
      },
    buttonHover: {
      background: "#0056b3",
    },
  };
