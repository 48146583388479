import React, { useState } from "react";

const Modal = ({ user, onClose, onEdit, onDelete }) => {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [main_balance, setMain_balance] = useState(user.main_balance);
  const [kyc_status, setKyc_status] = useState(user.kyc_status);
  const [nationality, setNationality] = useState(user.nationality);

  const handleSave = () => {
    onEdit({ ...user, name, email, main_balance,kyc_status, nationality });
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h2>Edit User</h2>
        <div style={styles.formGroup}>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Main Balance:</label>
          <input
            type="text"
            value={main_balance}
            onChange={(e) => setMain_balance(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label>KYC</label>
          <input
            type="text"
            value={kyc_status}
            onChange={(e) => setKyc_status(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Country</label>
          <input
            type="text"
            value={nationality}
            onChange={(e) => setNationality(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.buttons}>
          <button style={styles.saveButton} onClick={handleSave}>
            Save
          </button>
          <button
            style={styles.deleteButton}
            onClick={() => onDelete(user.users_id)}
          >
            Delete
          </button>
          <button style={styles.closeButton} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "400px",
    maxWidth: "90%",
    textAlign: "center",
  },
  formGroup: {
    marginBottom: "5px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginTop: "1px",
    borderRadius: "4px",
    border: "1px solid #ddd",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  saveButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  deleteButton: {
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  closeButton: {
    backgroundColor: "#ccc",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
};
