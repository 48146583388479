import React, { useState, useEffect } from "react";
import { makeRequest } from "../../axios";
import "./admin.scss"
import FlashMessage from "./flashmessage";
import Modal from "./modal3";



const CryptoWithdrawalReceipt = ({ receiptData }) => {
  // Example receipt data
  const [withdrawalReq, setWithdrawalReq] = useState([])
  const [err, setErr] = useState(null);
  const [app, setApp] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);


  const handleUserClick = (request) => {
    setSelectedUser(request);
  };

  const handleModalClose = () => {
    setSelectedUser(null);
  };

  const closeFlashMessage = () => {
    setFlashMessage(null);
  };


  const handleWithdrawalReq = async (request) => {
    if (window.confirm(`you are about to Approve Withdrawal for ${request.email}`)) {
    try {
      await makeRequest.post(`admin/withdrawal/approve/${request.transaction_id}`, request);
    } catch (err) {
      setErr(err.response.data);
    }
    setFlashMessage({
        message: "Withdrawal Approved",
        type: "success", // Can be 'success', 'error', 'info', or 'warning'
      });
    }else {
      console.log("deposit not approved")
     }
    setSelectedUser(null);
    setApp(!app)
  };

  
  const handleWithdrawalFailed = async (request) => {
    if (window.confirm(`you are about to Cancell Withdrawal for ${request.email}`)) {
    try {
      await makeRequest.get(`admin/withdrawal/failed/${request.transaction_id}`);
    } catch (err) {
      setErr(err.response.data);
    }
    setFlashMessage({
        message: "Withdrawal cancelled",
        type: "warning", // Can be 'success', 'error', 'info', or 'warning'
      });
    } else {
      console.log("witdrawal not cancelled")
    }
    setSelectedUser(null);
    setApp(!app)
  };



  useEffect(() => {
    makeRequest.get("admin/withdrawal").then((res) => {
      setWithdrawalReq(res.data)
    } 
    )
  }, [app]);

  return (
    <div className="deposit-requests-page">
      <h1 className="title">Withdrawal Requests</h1>
      {flashMessage && (
        <FlashMessage
          message={flashMessage.message}
          type={flashMessage.type}
          duration={1500} // Auto-hide after 3 seconds
          onClose={closeFlashMessage}
        />
      )}

      <div className="table-container">
        <table className="animated-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Currency</th>
              <th>Wallet</th>
              <th>Received</th>
            </tr>
          </thead>
          <tbody>
            {withdrawalReq.map((request) => (
              <tr key={request.id} className={`status-${request.trans_status.toLowerCase()}`} onClick={() => handleUserClick(request)} >
                <td>{request.email}</td>
                <td>{(parseFloat(request.amount || 0) || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>{request.trans_status}</td>
                <td>{request.method_details}</td>
                <td>{request.wallet}</td>
                <td>{request.received}</td>
      
                {/* <td>
                  {request.trans_status === "PENDING" ? (
                    <>
                      <button
                        className="approve-button"
                        onClick={() => handleApproveDeposit(request)}
                      >
                        Approve
                      </button>
                      <button
                        className="cancel-button"
                        onClick={() => handleCancelDeposit(request)}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <span className="status-indicator">{request.trans_status}</span>
                  )}
                </td> */}
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedUser && (
        <Modal
          user={selectedUser}
          onClose={handleModalClose}
          onEdit={handleWithdrawalReq}
          onDelete={handleWithdrawalFailed}
        />
      )}

    </div>
  );
};

// Styles
const styles = {
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // minHeight: "100vh",
    // backgroundColor: "#0a2e5b", // Dark blue background
    // padding: "20px",
  },
 p: {
color:"black"
 },
  container: {
    width: "100%",
    maxWidth: "1000px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    padding: "20px",
    textAlign: "center",
  },
  title: {
    color: "#0a2e5b",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  card: {
    // backgroundColor: "#f8f9fa",
    padding: "15px",
    borderRadius: "8px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    textAlign: "left",
    color: "black"
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  approveButton: {
    backgroundColor: "#28a745",
    color: "#ffffff",
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  cancelButton: {
    backgroundColor: "#dc3545",
    color: "#ffffff",
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  status: (status) => ({
    color:
      status === "Approved"
        ? "#28a745"
        : status === "Canceled"
        ? "#dc3545"
        : "#ffc107",
    fontWeight: "bold",
  }),
};

export default CryptoWithdrawalReceipt;
