import React, { useState, useEffect } from "react";
import { makeRequest } from "../../axios";
import './admin.scss'
import FlashMessage from "./flashmessage";


const WalletChangeForm = () => {
  // State to manage selected crypto, wallet address, and the wallets object
  const [message, setMessage] = useState("");
  const [selectedCrypto, setSelectedCrypto] = useState("BTC");
  const [walletAddress, setWalletAddress] = useState("");
  const [wallet1, setWallet1] = useState([]);
  const [app, setApp] = useState(false);
  const [wallets, setWallets] = useState({
    BTC: "",
    ETH: "",
    USDT: "",
    BTC_CASH: "",
    USDC: "",
  });

  const [flashMessage, setFlashMessage] = useState(null);


  const closeFlashMessage = () => {
    setFlashMessage(null);
  };

  // Handle crypto selection
  const handleCryptoChange = (e) => {
    setSelectedCrypto(e.target.value);
  };

  // Handle wallet address input
  const handleWalletChange = (e) => {
    setWalletAddress(e.target.value);
  };




  // Update wallet address
  const updateWallet = async (e) => {
    e.preventDefault();
    // setWallets((prevWallets) => ({
    //   ...prevWallets,
    //   [selectedCrypto]: walletAddress,
    // }));

    try {
        const response = await makeRequest.post("admin/wallet/update", {walletAddress,selectedCrypto});
        
        // Assuming the server sends a success message in the response
        setMessage(response.data.message);
      } catch (err) {
        // Handle errors, if needed
        console.error(err);
      }
      setFlashMessage({
        message: "Wallet updated",
        type: "success", // Can be 'success', 'error', 'info', or 'warning'
      });
     setApp(!app)
    setWalletAddress(""); // Clear input field after update
  };


  useEffect(() => {
    makeRequest.get("admin/wallet").then((res) => {
      setWallet1(res.data)
    } 
    )

  }, [app]);
  

  return (
    <div className="conti2" style={styles.container}>
      {/* Form Section */}
      <div className="conti3" style={styles.formContainer}>
        <h2 className="conti4" style={styles.heading}>Change Wallet Address</h2>
        {flashMessage && (
        <FlashMessage
          message={flashMessage.message}
          type={flashMessage.type}
          duration={1000} // Auto-hide after 3 seconds
          onClose={closeFlashMessage}
        />
      )}
        <form onSubmit={updateWallet} style={styles.form}>
          <label style={styles.label}>Select Crypto Currency:</label>
          <select
            value={selectedCrypto}
            onChange={handleCryptoChange}
            style={styles.select}
          >
            {Object.keys(wallets).map((crypto) => (
              <option key={crypto} value={crypto}>
                {crypto}
              </option>
            ))}
          </select>

          <label style={styles.label}>Wallet Address:</label>
          <input
            type="text"
            value={walletAddress}
            onChange={handleWalletChange}
            placeholder="Enter new wallet address"
            style={styles.input}
            required
          />

          <button type="submit" style={styles.button}>
            Update Wallet
          </button>
        </form>
      </div>

      {/* Table Section */}
      <div style={styles.tableContainer}>
        <h3 style={styles.tableHeading}>Wallet Details</h3>
        <table style={styles.table}>
          <thead>
            <tr>
              {/* <th style={styles.th}>Wallet id</th> */}
              <th style={styles.th}>Crypto Token</th>
              <th style={styles.th}>Wallet Address</th>
              <th style={styles.th}>Last changed</th>
            </tr>
          </thead>
          <tbody>
            {wallet1.map((crypto) => (
              <tr key={crypto.wallet_id}>
                {/* <td style={styles.td}>{crypto.wallet_id}</td> */}
                <td style={styles.td}>{crypto.wallet_type}</td>
                <td style={styles.td}>{crypto.wallet_address}</td>
                <td style={styles.td}>{crypto.wallet_changed}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// CSS-in-JS styles
const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "20px",
    backgroundColor: "rgb(248 249 250 / 0%)",
  },
  formContainer: {
    flex: "1",
    maxWidth: "500px",
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "8px",
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  },
  tableContainer: {
    flex: "1",
    maxWidth: "650px",
    backgroundColor: "white",
    color: "black",
    // padding: "20px",
    borderRadius: "8px",
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
    overflowX: "auto",
  },
  heading: {
    fontSize: "1.5rem",
    marginBottom: "20px",
    textAlign: "center",
  },
  tableHeading: {
    fontSize: "1.2rem",
    marginBottom: "10px",
    // textAlign: "center",
  },
  form: {
    height: "90%",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontWeight: "bold",
  },
  select: {
    marginBottom: "15px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "1rem",
  },
  input: {
    marginBottom: "15px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "1rem",
  },
  button: {
    marginTop:"8rem",
    padding: "10px",
    backgroundColor: "#0074D9",
    color: "#ffffff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "1rem",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
  },
  th: {
    textAlign: "left",
    padding: "10px",
    backgroundColor: "#a3a8ada1",
    color: "#ffffff",
    borderBottom: "1px solid #ddd",
  },
  td: {
    padding: "10px",
    // borderBottom: "1px solid #ddd",
    marginTop:".5rem"
  },
};

export default WalletChangeForm;
