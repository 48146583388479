import { useEffect } from 'react';

const ViewportSetter = ({ content }) => {
  useEffect(() => {
    const metaViewport = document.querySelector('meta[name="viewport"]');

    // If a viewport tag already exists, update its content
    if (metaViewport) {
      metaViewport.setAttribute('content', content);
    } else {
      // Otherwise, create a new viewport tag
      const newMetaViewport = document.createElement('meta');
      newMetaViewport.name = 'viewport';
      newMetaViewport.content = content;
      document.head.appendChild(newMetaViewport);
    }

    // Optionally reset to default on unmount
    return () => {
      if (metaViewport) {
        metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
      }
    };
  }, [content]);

  return null; // No UI, this component only sets meta tags
};

export default ViewportSetter;
