import React, { useState, useEffect } from "react";
import Modal from "./moda1";
import { makeRequest } from "../../axios";
import FlashMessage from "./flashmessage";


const Manage = () => {
  const [users, setUsers] = useState([]);
  const [allUser, setAllUsers] = useState([])
  const [err, setErr] = useState(null);
  const [app, setApp] = useState(false);


  const [selectedUser, setSelectedUser] = useState(null); // Tracks the user to be edited
  const [flashMessage, setFlashMessage] = useState(null);





  const closeFlashMessage = () => {
    setFlashMessage(null);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleModalClose = () => {
    setSelectedUser(null);
  };

  const handleEditUser = async (updatedUser) => {
    // setUsers((prevUsers) =>
    //   prevUsers.map((user) =>
    //     user.id === updatedUser.id ? updatedUser : user
    //   )
    // );
    try {
      await makeRequest.post(`admin/update/${updatedUser.users_id}`, updatedUser);
      // setSelectedComponent('manage-users') 
    } catch (err) {
      setErr(err.response.data);
    }
    setFlashMessage({
      message: "User info Edited!",
      type: "warning", // Can be 'success', 'error', 'info', or 'warning'
    });
    setApp(!app)
    handleModalClose();
  };

  const handleDeleteUser = async (userId) => {

    if (window.confirm(`You are about to delete user with UserID ${userId}`)) {
      try {
        await makeRequest.post(`admin/user/delete/${userId}`);
        // setSelectedComponent('manage-users') 
      } catch (err) {
        setErr(err.response.data);
      }
    } else {
      console.log("Action canceled!");
    }
    setFlashMessage({
      message: "User deleted!",
      type: "info", // Can be 'success', 'error', 'info', or 'warning'
    });
    
    setApp(!app)
    handleModalClose();
  };



  useEffect(() => {
    makeRequest.get("admin/getusers").then((res) => {
      setAllUsers(res.data)
    } 
    )
  }, [app]);

  return (
    <div style={styles.container}>

       {flashMessage && (
        <FlashMessage
          message={flashMessage.message}
          type={flashMessage.type}
          duration={1500} // Auto-hide after 3 seconds
          onClose={closeFlashMessage}
        />
        )}

      <h3 style={styles.header}>PLATFORM USERS</h3>
      <h6 style={{padding:"1rem", color:"#3570bb"}}>registered users on the platform</h6>
      <div style={styles.userList}>
        {allUser.map((user) => (
          <div
            key={user.users_id}
            style={styles.userCard}
            onClick={() => handleUserClick(user)}
          >
            <h3>{user.name}</h3>
            <h6>{user.email}</h6>
          </div>
        ))}
      </div>
      {selectedUser && (
        <Modal
          user={selectedUser}
          onClose={handleModalClose}
          onEdit={handleEditUser}
          onDelete={handleDeleteUser}
        />
      )}
    </div>
  );
};

export default Manage;

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  },
  header: {
    // textAlign: "center",
    borderRadius:"5px",
    backgroundColor: "#f2f4f9",
    marginBottom: "20px",
    padding:"1rem"
  },
  userList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
    justifyContent: "center",
  },
  userCard: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "15px",
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: "#f9f9f9",
    transition: "transform 0.2s, box-shadow 0.2s",
    minWidth: "150px",
  },
};
