import React, { useState, useEffect } from "react";
import "./admin.scss";
import { makeRequest } from "../../axios";
import FlashMessage from "./flashmessage";


const KycRequests = () => {
  const [chkim, setChkim] = useState(null)
  

  const [selectedImage, setSelectedImage] = useState(null);
  const [kyc, setKyc] = useState([])
  const [err, setErr] = useState(null);
  const [updat, setUpdat] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null);


  const closeFlashMessage = () => {
    setFlashMessage(null);
  };


//   const handleApproveKyc = async (item) => {
//     try {
//       await makeRequest.get(`admin/postkyc/approve/${item.users_id}`);
//     } catch (err) {
//       setErr(err.response.data);
//     }
//   };


  // Approve KYC Request
  const approveRequest = async (request) => {
    alert(`KYC for request ID ${request.email} approved!`);
    // setRequests((prevRequests) => prevRequests.filter((req) => req.id !== id));
    try {
        await makeRequest.get(`admin/postkyc/approve/${request.users_id}`);
      } catch (err) {
        setErr(err.response.data);
      }
      setUpdat(!updat)

  };

  // Cancel KYC Request
  const cancelRequest = (id) => {
    alert(`KYC for request ID ${id} cancelled!`);
    // setRequests((prevRequests) => prevRequests.filter((req) => req.id !== id));
  };

  useEffect(() => {
    makeRequest.get("admin/kyc").then((res) => {
      setKyc(res.data)
    } 
    )
  }, [updat]);


//   useEffect(() => {
//     setChkim((prev) => ({ ...prev, file: kyc.file, file1:kyc.file1 }));
//   }, [kyc]);

 console.log(kyc, "uoo")
//  console.log(kyc[1].file, "uoo")
console.log(selectedImage, "ui")
console.log(chkim, "lop")
  return (
    <div className="kyc-container">
      <h1 style={{color:"black"}}>Kyc Requests</h1>
      {flashMessage && (
        <FlashMessage
          message={flashMessage.message}
          type={flashMessage.type}
          duration={1000} // Auto-hide after 3 seconds
          onClose={closeFlashMessage}
        />
      )}
      <div className="kyc-requests">
        {kyc.map((request) => (
          <div key={request.users_id} className="kyc-card">
            <h3>{request.name}</h3>
            <p>{request.email}</p>
            <p>{request.address}</p>
            <p>{request.city}</p>
            <button
              className="preview-btn"
              onClick={() => {setChkim((prev) => ({ ...prev, file: request.file, file1:request.file2 })); setSelectedImage(request.file)}}
            >
              View Document
            </button>
            <div className="actions">
              <button
                className="approve-btn"
                onClick={() => approveRequest(request)}
              >
                Approve
              </button>
              
            </div>
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="image-preview">
          <div className="overlay" onClick={() => setSelectedImage(null)}></div>
          <img src={chkim.file} alt="Document Preview"  style={{width:"25%"}}/>
          <img src={chkim.file1} alt="Document Preview" style={{width:"25%"}} />
        </div>
      )}
    </div>
  );
};

export default KycRequests;
