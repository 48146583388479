import React, { useState, useEffect } from "react";
import "./int.scss"; // Import the CSS for styling
import { makeRequest } from "../../axios";
import FlashMessage from "./flashmessage";


const UserInterestPage = () => {
  const [data, setData] = useState([]); // State to hold fetched data
  const [interest, setInterest] = useState(""); // State for selected interest
  const [names, setNames] = useState({}); // State for user name input
  const [err, setErr] = useState(null);
  const [app, setApp] = useState(false);
  const [flashMessage, setFlashMessage] = useState(null);





 const handlname = (e) => {
    const {name, value} = e.target
    setNames((prev) => ({ ...prev, [name]:value, category:interest}))
 }
 
 const closeFlashMessage = () => {
  setFlashMessage(null);
};

  // Fetch data from an API
  useEffect(() => {
    makeRequest.get("admin/getint").then((res) => {
        setData(res.data)
      } 
      )
  }, [app]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm(`you are about to add/charge interest of $${names.amount} to user ${names.userid}`)) {
      console.log(names)
      try {
        await makeRequest.post("trans/interest",names);
      } catch (err) {
        setErr(err.response.data);
      }
      setFlashMessage({
        message: "deposit approved!",
        type: "info", // Can be 'success', 'error', 'info', or 'warning'
      });
      setNames({userid:"",amount:"", tezt:""});
      setInterest("");
    }
    setFlashMessage(null);
    setApp(!app)
  };

  return (
    <div className="cont">
      
      {flashMessage && (
        <FlashMessage
          message={flashMessage.message}
          type={flashMessage.type}
          duration={1500} // Auto-hide after 3 seconds
          onClose={closeFlashMessage}
        />
      )}

      <h2>INTERESTS</h2>
      <div className="contw">
        {/* Table of fetched data */}
        <div className="tablecont">
          <h4>ASSET INTERESTS</h4>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Wallet</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Transaction</th>
                <th>Created</th>
                

              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user.id}>
                  <td>{user.username}</td>
                  <td>{user.wallet}</td>
                  <td>{parseInt(user.amount,0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  <td>{user.trans_status}</td>
                  <td>{user.method_details}</td>
                  <td>{user.received}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* User Interest Form */}
        <div className="fcont">
          <h2>Submit Your Interest</h2>
          <form onSubmit={handleSubmit}>
            <div className="formg">
              <label htmlFor="userid">USER EMAIL</label>
              <input
                type="text"
                id="userid"
                name="userid"
                value={names.userid}
                onChange={handlname}
                placeholder="Enter USER EMAIL"
              />
            </div>
            
            <div className="formg">
              <label htmlFor="interest">Select Interest</label>
              <select
                id="interest"
                value={interest}
                onChange={(e) => setInterest(e.target.value)}
              >
                <option value="" disabled>
                  -- Select a category --
                </option>
                <option value="asset_balance">Asset interest</option>
                <option value="trading_balance">INTEREST</option>
                <option value="total_deposit">DEPOSIT</option>
                <option value="CHARGE">CHARGE</option>
                <option value="main_balance">CREDIT</option>
              </select>
            </div>
            <div className="formg">
              <label htmlFor="amount">AMOUNT $ </label>
              <input
                type="text"
                id="amount"
                name="amount"
                value={names.amount}
                onChange={handlname}
                placeholder="Enter amount"
              />
            </div>
            <div className="formg">
            <label htmlFor="tezt">REMARKS</label>
           <br />
            <textarea
             id="textarea"
             name="tezt"
             value={names.tezt}
             onChange={handlname}
             rows="3" // Number of visible text lines
             cols="30" // Width of the text area
             placeholder="Remarks"
             />
            <br />

            </div>
            <button className="bot" type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserInterestPage;
