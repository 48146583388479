import React, { useState, useEffect } from "react";
import { makeRequest } from "../../axios";
import FlashMessage from "./flashmessage";


const AdminPanel = () => {
  // State for managing the user password change
  const [err, setErr] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const closeFlashMessage = () => {
    setFlashMessage(null);
  };

  // State for admin functionality
  const [userEmail, setUserEmail] = useState({email:""});
  const [admins, setAdmins] = useState([]);

  // State for feedback messages
  const [message, setMessage] = useState("");

  // Handle password change submission
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = passwordData;

    if (!currentPassword || !newPassword || !confirmPassword) {
      setMessage("Please fill out all password fields.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage("New passwords do not match.");
      return;
    }

    if (newPassword.length < 8) {
      setMessage("New password must be at least 8 characters long.");
      return;
    }

    try {
      await makeRequest.post('auth/passupdateadmin',passwordData );
      // setSelectedComponent('manage-users') 
    } catch (err) {
      setErr(err.response.data);
    }

    setFlashMessage({
      message: "password changed",
      type: "success", // Can be 'success', 'error', 'info', or 'warning'
    });
    setPasswordData({ currentPassword: "", newPassword: "", confirmPassword: "" });
  };

  // Handle grant admin submission
  const handleGrantAdmin = async (e) => {
    e.preventDefault();

    if (!userEmail) {
      setMessage("Please enter a user email.");
      return;
    }

    try {
      await makeRequest.post('admin/makeadmin',userEmail );
      // setSelectedComponent('manage-users') 
    } catch (err) {
      setErr(err.response.data);
    }
    console.log(userEmail)
    setFlashMessage({
      message: "user granted admin previledges",
      type: "warning", // Can be 'success', 'error', 'info', or 'warning'
    });
    setUserEmail({email:""});
  };


  useEffect(() => {
    makeRequest.get("admin/getadmins").then((res) => {
      setAdmins(res.data)
    } 
    )
  }, []);


  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Admin Panel</h1>
      {flashMessage && (
        <FlashMessage
          message={flashMessage.message}
          type={flashMessage.type}
          duration={1000} // Auto-hide after 3 seconds
          onClose={closeFlashMessage}
        />
      )}

      <div style={styles.sections}>
        {/* Password Change Section */}
        <div style={styles.section}>
          <h2>Change Admin Password</h2>
          <form style={styles.form} onSubmit={handlePasswordChange}>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="currentPassword">
                Current Password
              </label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={passwordData.currentPassword}
                onChange={(e) =>
                  setPasswordData({ ...passwordData, currentPassword: e.target.value })
                }
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="newPassword">
                New Password
              </label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={passwordData.newPassword}
                onChange={(e) =>
                  setPasswordData({ ...passwordData, newPassword: e.target.value })
                }
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="confirmPassword">
                Confirm New Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={(e) =>
                  setPasswordData({ ...passwordData, confirmPassword: e.target.value })
                }
                style={styles.input}
              />
            </div>
            <button type="submit" style={styles.button}>
              Change Password
            </button>
          </form>
        </div>

        {/* Grant Admin Section */}
        <div style={styles.section}>
          <h2>Grant Admin Rights</h2>
          <form style={styles.form} onSubmit={handleGrantAdmin}>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="userEmail">
                User Email
              </label>
              <input
                type="email"
                id="userEmail"
                value={userEmail.email}
                onChange={(e) => setUserEmail({ ...userEmail, email: e.target.value})}
                style={styles.input}
              />
            </div>
            <button type="submit" style={styles.button}>
              Grant Admin
            </button>
          </form>
        </div>

        {/* Admin List Section */}
        <div style={styles.section}>
          <h2>Admin List</h2>
          <ul style={styles.list}>
            {admins.map((admin, index) => (
              <>
              <li key={index} style={styles.listItem}>
                {admin.email}
              </li>
              </>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  message: {
    textAlign: "center",
    color: "green",
    marginBottom: "20px",
  },
  sections: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  section: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  formGroup: {
    textAlign: "left",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "10px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
  list: {
    listStyleType: "none",
    padding: 0,
  },
  listItem: {
    padding: "5px 0",
  },
};
