import React, { useState,useContext } from 'react';
import OTPInput from "./otp1"
import dlog from "../../assets/logo.png"
import { AuthContext } from "../../authcontext/authContext";
import { Link, useNavigate } from "react-router-dom";
import "./otp.scss"


const OTPPage = () => {
  const [inputs, setInputs] = useState({otp: ""});
const { Otpverify } = useContext(AuthContext);
const { currentUser } = useContext(AuthContext);
  
 
const navigate = useNavigate();

  const handleOtpChange = (value,e) => {
    setInputs((prev) => ({ ...prev, ["otp"]: value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await Otpverify(inputs);
      navigate("/login")
    } catch (err) {
      
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', backgroundColor: 'white', color: 'black', padding: '50px' }}>
    <img className="brand-logo" src={dlog} alt="" style={{width:"50%"}} />
       <h4 style={{textAlign:"center"}}>
        To complete the registration process,
         please check your email: <br></br> {currentUser.email}  inbox for the One-Time Password
          (OTP) we've sent. <br/>
        </h4>
      <h1 style={{color:"#000000"}}>Enter OTP</h1>
      <OTPInput length={6} onChange={handleOtpChange} />
      {/* <p>Entered OTP: {otp}</p> */}
      <input className="regy2" style={{ cursor:"pointer"}} type="submit" value="Register" onClick={handleClick} />
    </div>
  );
};

export default OTPPage;
