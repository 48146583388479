import { BrowserRouter as Router , Routes, Route } from 'react-router-dom';
import Mainlayout from './Mainlayout';
import Register from './pages/register/Register';
import Login from './pages/login/Login';
import Admin1 from './pages/admin1/admin1';
import UserDashboard from './UserDashboard';
import InvestorTransaction from "./components/investorTransactions/InvestorTransaction";
// import Homepagelayout from './HomepageLayout';
import Kpackp from './pages/adress/adress';
import Kpackp2 from './pages/adress2/adress';
import Kpackp3 from './pages/adress3/adress';
import Kpackp4 from './pages/adress4/adress';
import Kpackp5 from './pages/adress5/adress';
import Cert from './pages/certificate/certificate';
import { useEffect, useState } from 'react';
import { makeRequest } from "./axios";
import Otpverify from './pages/otpverify/otp2';
import Passr from './pages/login/passwordre';
import Emailver from './pages/login/emailver';
import Changepass from './pages/login/changepass';
import { AuthContext } from './authcontext/authContext';
import { useContext} from "react";

function App() {

  const [userInfo, setUserInfo] = useState(null);
  const [linfo, setLinfo] = useState(0);
  const { currentUser } = useContext(AuthContext);


  const isLogged = true

  return (
    <Router>
      <Routes>
      <Route path="*" element={<Mainlayout />} />
      <Route path='/235689_certificate' element={<Cert /> } /> 
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="dashboard" element={ currentUser ? <UserDashboard/> : <Login/>} />
      <Route path="/emailverify" element={<Otpverify />} />
        <Route path="/changepass" element={<Changepass />} />
        <Route path="/emailver" element={<Emailver />} />
        <Route path="/forgottenpassword" element={<Passr />} />
      <Route path='/admin' element={ isLogged ? <Admin1 /> : <Login/>} />


          <Route path='/adress' element={<Kpackp />} />
          <Route path='/adressETH' element={<Kpackp2 />} />
          <Route path='/adressBTCH' element={<Kpackp3 />} />
          <Route path='/adressUSDT' element={<Kpackp4 />} />
          <Route path='/adressUSDC' element={<Kpackp5 />} />
          <Route path='/alltransactions/:useId' element={<InvestorTransaction />} />
  
      </Routes>
    </Router>
  );
}

export default App;
