import React, { useState, useEffect, useContext } from "react";
import "./userpro.scss";
// import { color } from "framer-motion";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ChangePasswordModal from "./chpass";
import { makeRequest } from "../../axios";
import { ConstructionOutlined } from "@mui/icons-material";



const UserPage = ({balg}) => {
  const [user, setUser] = useState({
    name: "",
    phone: "",
    country: "",
    city: "",
    gender: "",
    address: "",
    avatar:"",
    avatar1:""
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passd, setPassd] = useState({newpass:"", email:""})
  const [formData, setFormData] = useState(user);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [newAvatar, setNewAvatar] = useState("");
  const [isModalOpen7, setIsModalOpen7] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  // const { bal } = useContext(AuthContext);
  // const { currentUser } = useContext(AuthContext);
  const [err, setErr] = useState(null)
  const [save, setSave] = useState(false)


  useEffect(() => {
    makeRequest.get("user-info/user").then((res) => {
      setUserInfo(res.data.info);
      setFormData(res.data.info)
    });
  }, [save]);

  const handleChangePassword = async ( passd) => {
    
    try {
      await makeRequest.post("auth/passupdate1",passd);
    } catch (err) {
      setErr(err.response.data);
      console.log(err);
    }
    console.log(passd)
    // Add logic to update the password on the server
  };


  const handleAvatarUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setNewAvatar(reader.result);
      reader.readAsDataURL(file);
    }
    
  };


  const handleSaveAvatar = async () => {
    
    if (newAvatar) {
      try {
        
        const updatedUser = { ...user, avatar1: newAvatar };
        await makeRequest.post("user-info/updateuser", updatedUser);

        setUser(updatedUser);  
        console.log(updatedUser, "olp")
      } catch (err) {
        setErr(err.response.data);
        console.log(err);
      }
      
      setIsAvatarModalOpen(false);
      
    }
    
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value, avatar1:"" });
    
  };

  const handleSave = async () => {
    console.log(formData)
    try {
      await makeRequest.post("user-info/updateuser",formData);
    } catch (err) {
      setErr(err.response.data);
      console.log(err);
    }
    setSave(!save)
    setIsModalOpen(false);
  };

  const balanx = parseFloat(userInfo.main_balance || 0) || 0;
  const balanx2 = balanx.toLocaleString('en-US', {
  minimumFractionDigits: 2,maximumFractionDigits: 2});
 

  return (
    <div className="user-page">
      <div className="user-card">
        <div className="avtad">
        <img src={formData.avatar} alt="User Avatar" className="user-avatar"  />
        <CameraAltIcon className="edit-icon1" onClick={() => setIsAvatarModalOpen(true)}/>


        {isAvatarModalOpen && (
        <div className="modal-overlay" onClick={() => setIsAvatarModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Upload New Avatar</h3>
            <input
              type="file"
              accept="image/*"
              onChange={handleAvatarUpload}
            />
            {newAvatar && (
              <img
                src={newAvatar}
                alt="New Avatar Preview"
                className="avatar-preview"
                style={{width:"22rem", height:"17rem"}}
              />
            )}
            <div className="modal-actions">
              <button className="save-btn" onClick={handleSaveAvatar}>
                Save
              </button>
              <button
                className="cancel-btn"
                onClick={() => setIsAvatarModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
         </div>



        <div className="user1">

        <div className="subuser">
        <p>{userInfo.name}</p>
        <p onClick={() => setIsModalOpen(true)} style={{cursor:"pointer", color:"blue"}}>
          Edit Profile
        </p>
        </div>
         <hr ></hr>
         <div className="subuser">
        <p>
          <strong> Total Balance</strong> 
        </p>
        <p>{balanx2}</p>
       </div>

       </div>
       

       <div className="user2">

        <div className="subuser">
        <p>
          <strong>Phone</strong> 
        </p>
        <p>{userInfo.phone}</p>
        </div>
        <hr ></hr>
       <div className="subuser">
        <p>
          <strong>Country</strong> 
        </p>
        <p>{userInfo.nationality}</p>
       </div>
       <hr ></hr>
       <div className="subuser">
        <p>
          <strong>City</strong> 
        </p>
        <p>{userInfo.city}</p>
        </div>
        <hr ></hr>
        <div className="subuser">
        <p>
          <strong>Gender</strong> 
        </p>
        <p>{userInfo.gender}</p>
        </div>
        <hr ></hr>
       <div className="subuser">
        <p>
          <strong>Address</strong> 
        </p>
        <p>{userInfo.address}</p>
        </div>
        <hr ></hr>
       <div className="subuser">
        <p>
          <strong>Email</strong> 
        </p>
        <p>{userInfo.email}</p>
       </div>

        </div>

        <div style={{width:"100%", textAlign:"left", margin:"1rem 0"}}>SECURITY</div>

        <div className="user1" style={{marginBottom:"3rem", cursor:"pointer"}}  onClick={() => setIsModalOpen7(true)}>

        <div className="subuser">
        <p  style={{color:"red"}}>
          Change Password
        </p>
        </div>
       </div>

      </div>
      <ChangePasswordModal
        isOpen={isModalOpen7}
        onClose={() => setIsModalOpen7(false)}
        onChangePassword={handleChangePassword}
      />
      {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div  className="modal-content" onClick={(e) => e.stopPropagation()} style={{backgroundColor:"#546b8c59"}}>
            <h3>Edit Profile</h3>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Country</label>
              <input
                type="text"
                name="country"
                value={formData.nationality}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Gender</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
              >
                <option value=""></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
            <div className="modal-actions">
              <button className="save-btn" onClick={handleSave}>
                Save
              </button>
              <button
                className="cancel-btn"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPage;
