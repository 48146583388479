import React, { useState, useEffect } from "react";
import "./admin.scss";
import { makeRequest } from "../../axios";
import FlashMessage from "./flashmessage";





const TradesPage = () => {
  // Sample deposit requests
  const [depositReq, setDepositReq] = useState([])
  const [err, setErr] = useState(null);
  const [app, setApp] = useState(false);

  const [trades, setTrades] = useState([])





  useEffect(() => {
    makeRequest.get("admin/trades").then((res) => {
      setTrades(res.data)
    } 
    )

  }, []);





  return (
    <div className="deposit-requests-page">
      <h1 className="title">Deposit Requests</h1>
      

      <div className="table-container">
        <table className="animated-table">
          <thead>
            <tr>
              <th>User</th>
              <th>Stock</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Trading Balance</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {trades.map((request) => (
              <tr key={request.trade_id} className={`status-approved`}  >
                <td >{request.user}</td>
                <td>{request.stock}</td>
                <td>{request.amount}</td>
                <td>{request.date}</td>
                <td>{request.trading_balance}</td>
                <td>{request.email}</td>
       
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default TradesPage;
