import React, { useState, useContext } from "react";
import "./userpro.scss";
import { AuthContext } from "../../authcontext/authContext";


const ChangePasswordModal = ({ isOpen, onClose, onChangePassword }) => {
  
  const [error, setError] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [passd, setPassd] = useState({newPassword:"",confirmPassword:"",currentPassword:"" })
   const poli = "popp"

  const passd1 = (e) => {
  const {name, value} = e.target
  setPassd({...passd,  [name]:value, email:currentUser.email });

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (passd.newPassword !== passd.confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }
    
    setError("");
    onChangePassword(passd);
    onClose();
    setPassd({newPassword:"",confirmPassword:"",currentPassword:"" })
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>Change Password</h3>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="currentPassword">Current Password</label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={passd.currentPassword}
              onChange={passd1}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="text"
              id="newPassword"
              name="newPassword"
              value={passd.newPassword}
              onChange={passd1}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={passd.confirmPassword}
              onChange={passd1}
              required
            />
          </div>
          <div className="modal-actions">
            <button type="submit" className="save-btn">
              Change Password
            </button>
            <button type="button" className="cancel-btn" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
