

import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container,Col,
    Modal,
    ModalDialog,
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalFooter,
     Row,Card,CardBody,CardTitle,FormControl,Form,FormGroup, InputGroup } from 'react-bootstrap';
import './pol.css'
import './kol.css'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useState } from 'react';
import polm from '../../assets/BTC.svg'
import polm2 from '../../assets/ETH.svg'
import polm3 from '../../assets/BCH.svg'
import polm4 from '../../assets/usdtEr20.svg'
import polm5 from '../../assets/usdc.svg'

import ChildComponent from "./depodetail";
import { makeRequest } from "../../axios";
import { useNavigate } from "react-router-dom";




function Vetmodal(props) {

  const [inputs, setInputs] = useState({
    amount: 0,
    method_details:""
  });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      const method = "BITCOIN"
        setInputs((prev) => ({ ...prev, [name]: value, method_details: method }));
        
    };
    // const handleChange = (e) => {
    //   setInputs(e.target.value);
    // };
  
  
    const [err, setErr] = useState(null);
    const handleClick = async (e) => {
      e.preventDefault();
  
      try {
        await makeRequest.post("trans/deposit", inputs);
      navigate("/adress")
  
      } catch (err) {
        setErr(err.response.data);
      }
      
     };
  
     const navigate = useNavigate();


        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
           
            <div style={{backgroundColor:"#111213ed", color:"whitesmoke"}}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Bitcoin Deposit
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
             
            <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
       
       <InputGroup>
       <InputGroup.Text id="basic-addon1"><AttachMoneyIcon/></InputGroup.Text>
        <Form.Control variant="dark" type="number" name='amount' placeholder="Amount" onChange={handleChange}  />
        </InputGroup>
      </Form.Group>
    </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClick}>Proceed</Button>
            </Modal.Footer>
            </div>
          </Modal>
        );
      }

      function Vetmodal1(props) {

        const [inputs, setInputs] = useState({
          amount: 0,
          method_details:""
        });
        
        
          const handleChange = (e) => {
            const { name, value } = e.target;
            const method = "ETHEREUM"
              setInputs((prev) => ({ ...prev, [name]: value, method_details: method }));
          };
          // const handleChange = (e) => {
          //   setInputs(e.target.value);
          // };
        
        
          const [err, setErr] = useState(null);
          const handleClick = async (e) => {
            e.preventDefault();
        
            try {
              await makeRequest.post("trans/deposit", inputs);
            
        

            navigate("/adressETH")
            } catch (err) {
              setErr(err.response.data);
            }
           };
        
           const navigate = useNavigate();


        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div style={{backgroundColor:"#111213ed", color:"whitesmoke"}}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Ethereum Deposit
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
             
            <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
       
       <InputGroup>
       <InputGroup.Text id="basic-addon1"><AttachMoneyIcon/></InputGroup.Text>
        <Form.Control variant="dark" name='amount' type="number" placeholder="Amount" onChange={handleChange}/>
        </InputGroup>
      </Form.Group>
    </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClick}>Proceed</Button>
            </Modal.Footer>
            </div>
          </Modal>
        );
      }


      function Vetmodal2(props) {

        const [inputs, setInputs] = useState({
          amount: 0,
          method_details:""
        });
        
        
          const handleChange = (e) => {
            const { name, value } = e.target;
            const method = "BITCOIN CASH"
              setInputs((prev) => ({ ...prev, [name]: value, method_details: method }));
          };
          // const handleChange = (e) => {
          //   setInputs(e.target.value);
          // };
        
        
          const [err, setErr] = useState(null);
          const handleClick = async (e) => {
            e.preventDefault();
            
        
            try {
              await makeRequest.post("trans/deposit", inputs);
            navigate("/adressBTCH")
        
            } catch (err) {
              setErr(err.response.data);
            }
           };
        
           const navigate = useNavigate();

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div style={{backgroundColor:"#111213ed", color:"whitesmoke"}}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
             Bitcoin Cash Deposit
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
             
            <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
       
       <InputGroup>
       <InputGroup.Text id="basic-addon1"><AttachMoneyIcon/></InputGroup.Text>
        <Form.Control variant="dark" name='amount' type="number" placeholder="Amount" onChange={handleChange}/>
        </InputGroup>
      </Form.Group>
    </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClick}>Proceed</Button>
            </Modal.Footer>
           </div>
          </Modal>
        );
      }

      function Vetmodal3(props) {
        const [modalShow3, setModalShow3] = useState(true)
        const [selectedComponent, setSelectedComponent] = useState('method');

        const [inputs, setInputs] = useState({
          amount: 0,
          method_details:""
        });

        

        
          const handleChange = (e) => {
            const { name, value } = e.target;
            const method = "USDT"
              setInputs((prev) => ({ ...prev, [name]: value, method_details: method }));
          };
          // const handleChange = (e) => {
          //   setInputs(e.target.value);
          // };
        
        
          const [err, setErr] = useState(null);
          const handleClick = async (e) => {
            e.preventDefault();
            
        
            try {
              await makeRequest.post("trans/deposit", inputs);

            
            navigate("/adressUSDT")
            } catch (err) {
              setErr(err.response.data);
            }
           };
        
           const navigate = useNavigate();

        return (
          
          <>
         
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
           
          >
            <div style={{backgroundColor:"#111213ed", color:"whitesmoke"}}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                USDT ERC20 Deposit
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
             
            <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
       
       <InputGroup>
       <InputGroup.Text id="basic-addon1"><AttachMoneyIcon/></InputGroup.Text>
        <Form.Control variant="dark" type="number" name='amount' placeholder="Amount" onChange={handleChange}/>
        </InputGroup>
      </Form.Group>
    </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button   onClick={handleClick}  id="close">Proceed</Button>
            </Modal.Footer>
            </div>
          </Modal>


          </>
        );
      }


      function Vetmodal4(props) {
        const [modalShow4, setModalShow4] = useState(true)
        const [selectedComponent, setSelectedComponent] = useState('method');

        const [inputs, setInputs] = useState({
          amount: 0,
          method_details:""
        });

        

        
          const handleChange = (e) => {
            const { name, value } = e.target;
            const method = "USDC"
              setInputs((prev) => ({ ...prev, [name]: value, method_details: method }));
          };
          // const handleChange = (e) => {
          //   setInputs(e.target.value);
          // };
        
        
          const [err, setErr] = useState(null);
          const handleClick = async (e) => {
            e.preventDefault();
            
        
            try {
              await makeRequest.post("trans/deposit", inputs);

            
            navigate("/adressUSDC")
            } catch (err) {
              setErr(err.response.data);
            }
           };
        
           const navigate = useNavigate();

        return (
          
          <>
         
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
           
          >
            <div style={{backgroundColor:"#111213ed", color:"whitesmoke"}}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                USDC ERC20 Deposit
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
             
            <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
       
       <InputGroup>
       <InputGroup.Text id="basic-addon1"><AttachMoneyIcon/></InputGroup.Text>
        <Form.Control variant="dark" type="number" name='amount' placeholder="Amount" onChange={handleChange}/>
        </InputGroup>
      </Form.Group>
    </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button   onClick={handleClick}  id="close">Proceed</Button>
            </Modal.Footer>
            </div>
          </Modal>


          </>
        );
      }

export default function Pay() {

    const timy = new Date()
    const limo = timy.toDateString()
    const [click, Setclick] = useState(true)

    const dclick =()=> {
        Setclick(false)
    }
    const [clicks, Setclicks] = useState(true)

    const pclick =()=> {
        Setclicks(false)
    }

      const [modalShow, setModalShow] = useState(false);
      const [modalShow1, setModalShow1] = useState(false)
      const [modalShow2, setModalShow2] = useState(false)
      const [modalShow3, setModalShow3] = useState(false)
      const [modalShow4, setModalShow4] = useState(false)
      const [selectedComponent, setSelectedComponent] = useState('methodo');

    return (
        
        <>
        {
       selectedComponent === "methodo" && (
        
        <Container fluid>
        
           <div style={{ color:"white"}} className="page-title-box">
           <h4>Deposit</h4>
               <div >
                   <Button size='sm'  >
                       &nbsp;{limo}
                     <CalendarMonthIcon/>
                   </Button>
               </div>
           </div>
             
            <div style={{display:"flex"}}>
           <Col xs={6} md="4" >
                    
                    <div className='stock-logo-holder' style={{textAlign:"center", padding:"2px",width:"95%",
                     backgroundColor:"transparent", border:"1px #292828 solid"}}>
                      <p style={{display:"flex", justifyContent:"center"}}>
                        <img  className='stock-logo' src={polm} style={{marginTop:".5rem"}}/>
                      </p>
                        <h4 style={{marginTop:"1rem"}} className='text-light' >Bitcoin</h4>
                        
                        <CardBody >
                            <hr  className='text-light' />
                        </CardBody>    
                        <Button variant="success" size="lg" onClick={() => setModalShow(true)}
                          
                         style={{width:"85%", marginBottom:"1.5rem", border:"2px dashed white"}} >
                            Deposit</Button> 
                            
                    </div>   
                
             </Col>

             <Vetmodal
                 show={modalShow}
                onHide={() => setModalShow(false)}
                    />
               <Vetmodal1
                 show={modalShow1}
                onHide={() => setModalShow1(false)}
                    />
                    <Vetmodal2
                 show={modalShow2}
                onHide={() => setModalShow2(false)}
                    />
                    <Vetmodal3
                 show={modalShow3}
                onHide={() => setModalShow3(false)}
                    />

                     <Vetmodal4
                   show={modalShow4}
                    onHide={() => setModalShow4(false)}
                    />


             <Col xs={6} md="4">
             <div className='stock-logo-holder' style={{textAlign:"center", padding:"2px",width:"95%",
                     backgroundColor:"transparent", border:"1px #292828 solid"}}>
                          <p style={{display:"flex", justifyContent:"center"}}>
                        <img  className='stock-logo' src={polm2}  style={{marginTop:".5rem"}}/>
                        </p>
                        <h4 style={{marginTop:"1rem"}} className='text-light' >Ethereum</h4>
                        
                            <Card.Body>
                            
                            <hr  className='text-light' />
                           
                            </Card.Body>
                            <Button variant="success" size="lg" onClick={() => setModalShow1(true)}
                          style={{width:"85%", marginBottom:"1.5rem", border:"2px dashed white"}} >
                             Deposit</Button> 

                    </div>
                
             </Col>

             <Col xs={6} md="4">
             <div className='stock-logo-holder' style={{textAlign:"center", padding:"2px",width:"95%",
                     backgroundColor:"transparent", border:"1px #292828 solid"}}>
                        <p style={{display:"flex", justifyContent:"center"}}>
                        <img  className='stock-logo' src={polm3}  style={{marginTop:".5rem"}}/>
                        </p>
                        <h4 style={{marginTop:"1rem"}} className='text-light' >Bitcoin Cash</h4>
                        
                            <Card.Body>
                            
                            <hr  className='text-light' />
                            
                            </Card.Body>
                            <Button variant="success" size="lg" onClick={() => setModalShow2(true)}
                          style={{width:"85%", marginBottom:"1.5rem", border:"2px dashed white"}} >
                             Deposit</Button>  
                    </div>
                
             </Col>

        </div>

        <div style={{display:"flex"}}>

        <Col xs={6} md="4">
        <div className='stock-logo-holder' style={{textAlign:"center", padding:"2px",width:"95%",
                     backgroundColor:"transparent", border:"1px #292828 solid"}}>
                      <p style={{display:"flex", justifyContent:"center"}}>
                        <img  className='stock-logo' src={polm4} style={{marginTop:".5rem"}}  />
                        </p>
                        <h4 style={{marginTop:"1rem"}} className='text-light' >USDT ERC20</h4>
                        
                            <Card.Body>
                        
                            <hr  className='text-light' />
                            
                            </Card.Body>
                            <Button variant="success" size="lg" onClick={() => setModalShow3(true)}
                          style={{width:"85%", marginBottom:"1.5rem", border:"2px dashed white"}} >
                             Deposit</Button>   
                    </div>
                
             </Col>

             <Col xs={6} md="4">
        <div className='stock-logo-holder' style={{textAlign:"center", padding:"2px",width:"95%",
                     backgroundColor:"transparent", border:"1px #292828 solid"}}>
                      <p style={{display:"flex", justifyContent:"center"}}>
                        <img  className='stock-logo' src={polm5} style={{marginTop:".5rem"}}  />
                        </p>
                        <h4 style={{marginTop:"1rem"}} className='text-light' >USDC ERC20</h4>
                        
                            <Card.Body>
                        
                            <hr  className='text-light' />
                            
                            </Card.Body>
                            <Button variant="success" size="lg" onClick={() => setModalShow4(true)}
                          style={{width:"85%", marginBottom:"1.5rem", border:"2px dashed white"}} >
                             Deposit</Button>   
                    </div>
                
             </Col>

        </div>

        </Container>
       )}

       {
      selectedComponent === "form" && (
        <>
        <ChildComponent />
        </>
      )}
            
         
       </>

    )
}
