import React from "react";
import "./admin.scss";
import { makeRequest } from "../../axios";
import { useEffect, useState } from "react";


const ReqTable = () => {

    const [recent, setRecent] = useState([])




    useEffect(() => {
        makeRequest.get("admin/alltransactions1").then((res) => {
          setRecent(res.data)
        } 
        )
    
      }, []);
    //  console.log(recent,"lop")

  return (
    <div className="container2">
      <div className="info-box2"><h2>Recent requests</h2></div>
      <div className="table-wrapper2">
        <table className="responsive-table2">
          <thead>
            <tr>
              <th>amount</th>
              <th>crypto</th>
              <th>status</th>
              <th>wallet</th>
              <th>type</th>
              
            </tr>
          </thead>
          <tbody>
            {recent.map((row, index) => (
              <tr key={row.transaction_id}>
                <td>{parseInt(row.amount,0).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{row.method_details}</td>
                <td>{row.trans_status}</td>
                <td>{row.wallet}</td>
                <td>{row.type}</td>
                
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReqTable;
