// ChildComponent.js
import { useState, useContext, useEffect } from "react";
import { makeRequest } from "../../axios";
import { Button, Container,Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalFooter,
     Row,Card,CardBody,CardTitle,FormControl,Form,FormGroup, InputGroup, CardHeader } from 'react-bootstrap';
     import { QRCode } from 'react-qrcode-logo';
import { CollectionsOutlined } from "@mui/icons-material";

const ChildComponent = ({ message }) => {
    const [inputs, setInputs] = useState({});
    const [err, setErr] = useState(null);
    const [wallets, setWallets] = useState([])
    const [wallets1, setWallets1] = useState([])
    const [mappedData, setMappedData] = useState({});
    const [usersData, setUsersData] = useState({});
    
     
    useEffect(() => {
        makeRequest.get(`admin/wallet/${mappedData.currency}`).then((res) => {
          setWallets1(res.data)
        } 
        )
    
      }, [mappedData]);
   
    useEffect(() => {
     setMappedData(message[0])   
     setInputs(message)
       console.log(inputs, "kolo")
      }, [message]);


      useEffect(() => {
        makeRequest.get("admin/wallet").then((res) => {
          setWallets(res.data)
        } 
        )
    
      }, []);
      console.log(wallets, "wall")

 
      
      
      // console.log(usersData, "oio")
      // console.log(inputs , "oiipp")
      // console.log(mappedData.currency, "lol")
      // console.log(wallets1,"main")

      const dats = wallets1[0]?.wallet_address
      

    const handleClick = async (e) => {
        e.preventDefault();
        setInputs(message)
        try {
          await makeRequest.post("trans/deposit", inputs);
    
        } catch (err) {
          setErr(err.response.data);
        }
        console.log(inputs, "kolo")
         window.location.reload();
      };

    // const refreshPage = () => {
    //      // Reload the entire page
    //   };

  return (
    <Card style={{width:"",backgroundColor: "darkblue", margin:"1rem"}}>
    <Card.Header
     style={{padding:"1.4rem .9rem",color:"white",
      borderBottom:"1px solid white",  backgroundImage: "linear-gradient(112.1deg, rgba(32, 38, 57, 1) 11.4%, rgba(63, 76, 119, 1) 70.2%)"}}><h2>Make a deposit</h2></Card.Header>
    <Card.Body style={{ backgroundImage:"radial-gradient(circle 815px at 23.4% -21.8%, rgb(11 25 63) 0.2%, rgba(0, 0, 0, 1) 100.2%)"}}>
      <div style={{padding:".8rem",backgroundImage: "radial-gradient(circle 815px at 23.4% -21.8%, rgba(9, 29, 85, 1) 0.2%, rgba(0, 0, 0, 1) 100.2%)"}}>
      <Card.Text style={{color:"white",padding: "2rem 1rem"}} >
     <div>
        <div style={{ marginBottom: '.5rem', textAlign:"center" }}>
          <QRCode value={dats} size={150} bgColor="#ffffff" fgColor="#000000" />
          <p>scan for wallet address</p>
        </div>
       
        <div>
        Add funds using your generated wallet address
      After your wallet address have been generated, <br></br>
      copy the wallet and fund your account through our secure payment vendor prompt
        </div>
        <div>
            <h1 style={{margin:"2rem 0"}}>
         {wallets.map((deposit, index) =>(
            
            <p style={{fontSize:"20px"}}>
                {mappedData?.currency === deposit?.wallet_type ? deposit?.wallet_address : null}
            </p>
            ))}
            </h1>
            </div>

        <div style={{margin:"1rem 0"}} >
        NOTE:YOU CAN ALSO
         MAKE YOUR INVESTMENT BY FUNDING YOUR ACCOUNT
          THROUGH OTHER MEANS SUCH AS; <br></br>
           USING YOUR WALLET TO FUND DIRECTLY TO YOUR ACCOUNT
        </div>
        <div style={{margin:"1.5rem 0"}}>
            <h3>deposit information</h3>
        <div style={{display:"flex", justifyContent:"space-between", marginBottom:".5rem"}}>
            <div>Cancel Depost</div> <span>Available with 10% fee (After min 24 hours) </span>
        </div>    
        <div style={{display:"flex", justifyContent:"space-between", marginBottom:".5rem"}}>
            <div>Debit Amount</div> <span>{message.map((deposit, index) =>(
                deposit.amount
            ))} </span>
        </div>  
        <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>Currency</div> <span>  {message.map((deposit, index) =>(
                deposit.currency
            ))}  </span>
        </div>    
          
     </div> 
     <div>
        <p>Please send exact amount in Cryptocurrencies or more.</p>
        <p style={{color:"yellow", }}>please make sure to click "Done" after payment has been made</p>
     </div>
        
     </div>
      </Card.Text>
      <Button onClick={handleClick} style={{margin:"1rem 0", width:"100%", padding:".7rem",borderRadius:"5px",
       backgroundImage:"linear-gradient(112.1deg, rgba(32, 38, 57, 1) 11.4%, rgba(63, 76, 119, 1) 70.2%)"}}  >Done</Button>
      </div>
    </Card.Body>
    </Card>
  );
};

export default ChildComponent;
