import React, { useState, useEffect } from "react";
import { makeRequest } from "../../axios";
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import ClearIcon from '@mui/icons-material/Clear';
import Button from 'react-bootstrap/Button';


import "./admin.scss";

const ContactMessage = () => {


const [reqa, setReqa] = useState([]);
const [investors, setAllInvestor] = useState([])
const [selectedComponent, setSelectedComponent] = useState('manage-investors1');
const [err, setErr] = useState(null);
const [userTrades, setAllTrades] = useState([])
const [alltransactions, setAllTransactions ] = useState([])
const [selectedInvestorsComponent, setSelectedInvestorsComponent] = useState('all-trans');
const [selectedUser, setSelectedUser] = useState(null)

const [message, setMessage] = useState("");
const [createUserTrades, setCreateUserTrades] = useState({
  amount: "",
  balance_id: "",
  stock: ""
})

const handleCreateTradeChange = (e) => {
  
  const { name, value } = e.target;
  setCreateUserTrades((prev) => ({ ...prev,balance_id:reqa, [name]: value }));
  
};

// const handleChange = (e) => {
//   const { name, value } = e.target;
//   setSelectedUser((prev) => ({ ...prev, [name]: value }));
// };

const handleCreatTradeSubmit = async (e) => {
  e.preventDefault();

  try {
    const response = await makeRequest.post("admin/trades/new", createUserTrades);
    
    // Assuming the server sends a success message in the response
    setMessage(response.data.message);
  } catch (err) {
    // Handle errors, if needed
    console.error(err);
  }

  setTimeout(() => {
    setMessage(null)
  }, 5000);
};

const handleAllTransactionsClick = async (item) => {
  try {
    const response = await makeRequest.get(`admin/alltransactions/${item.users_id}`, item);
    setAllTransactions(response.data);
  } catch (err) {
    setErr(err.response.data);
  }
};

const handleAllTradesClick = async (item) => {
  try {
    const response = await makeRequest.get(`admin/trades/${item.users_id}`);
    setAllTrades(response.data);
  } catch (err) {
    setErr(err.response.data);
  }
};


useEffect(() => {
  makeRequest.get("admin/investors").then((res) => {
    setAllInvestor(res.data)
  } 
  )

}, []);  


// useEffect(() => {
//     makeRequest.get("admin/contact").then((res) => {
//       setReqa(res.data)
//     } 
//     )

//   }, []);

  return (
    <>
   
{
        selectedComponent === "manage-investors1" && (

          <div className="deposit-requests-page">
      <h1 className="title">TRADE FOR INVESTORS</h1>
      

      <div className="table-container">
        <table className="animated-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Balance</th>
              <th>Total investment</th>
              <th>Country</th>
            </tr>
          </thead>
          <tbody>
            {investors.map((request, i) => (
              <tr key={request.id} className={`status-approved`} onClick={() => {handleAllTransactionsClick(request); handleAllTradesClick(request); setReqa(request.users_id); setSelectedComponent("user-transctions" ) }} >
                <td >{request.name}</td>
                <td>{request.email}</td>
                <td>{request.phone}</td>
                <td>{request.main_balance}</td>
                <td>{request.total_investment}</td>
                <td>{request.nationality}</td>
      
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>

        )
      }


{
  selectedComponent === "user-transctions" && (
      <div className="users" >
      <Nav defaultActiveKey="all-trans" as="ul">
      <Nav.Item as="li">
      <Nav.Link onClick={() => setSelectedInvestorsComponent("all-trans")} ><span className='' >Transactions </span></Nav.Link>
      </Nav.Item>
      <Nav.Item as="li">
      <Nav.Link onClick={() => setSelectedInvestorsComponent("trade") } ><span className='' >Trades </span></Nav.Link>
      </Nav.Item>
      <Nav.Item as="li">
      <Nav.Link onClick={() => setSelectedInvestorsComponent("create")} ><span className='' >Create trade </span></Nav.Link>
      </Nav.Item>
      <Nav.Item as="li">
      <Nav.Link onClick={() => setSelectedComponent("manage-investors1")} ><span className='he' > <ClearIcon /> Close </span></Nav.Link>
      </Nav.Item>
    </Nav>
    {
      selectedInvestorsComponent === "all-trans" && (
      
        
           <div className="deposit-requests-page">
         <h1 className="title">INVESTORS TRANSACTIONS</h1>


      <div className="table-container">
        <table className="animated-table">
            <thead>
      <tr>
        <th>Type</th>
        <th>Amount</th>
        <th>Coin</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {alltransactions.map((request, i) => (
        <tr key={i} className={`status-${request.trans_status.toLowerCase()}`} >
          <td >{request.type}</td>
          <td>{request.amount}</td>
          <td>{request.method_details}</td>
          <td>{request.trans_status}</td>

        </tr>
      ))}
    </tbody>
  </table>
   </div>

   </div>
      
      
      )
    }

      {
      selectedInvestorsComponent === "trade" && (
        <>
       
        <div className="deposit-requests-page">
         <h1 className="title">TRADES</h1>


      <div className="table-container">
        <table className="animated-table">
            <thead>
      <tr>
        <th>Asset Name</th>
        <th>Amount</th>
        <th>Date</th>
        
      </tr>
    </thead>
    <tbody>
      {userTrades.map((request, i) => (
        <tr key={i} className={`status-approved`} >
          <td >{request.stock}</td>
          <td>{request.amount}</td>
          <td>{request.date}</td>
      
        </tr>
      ))}
    </tbody>
  </table>
   </div>

   </div>
        </>
      )
    }
    {
      selectedInvestorsComponent === "create" && (
        <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}} >
        

       <Modal.Dialog>
        <Modal.Header
        onClick={() => setSelectedComponent("manage-users1")}        
         closeButton >
          <Modal.Title>Enter Trade</Modal.Title>
        </Modal.Header>
        <div className='col' style={{gap: "7px"}} >

        <input className='inpt' name='amount' placeholder="amount"  onChange={handleCreateTradeChange} />
        
        <input className='inpt' name='stock' placeholder="stock"  onChange={handleCreateTradeChange} />
        
        </div>
        <Button onClick={handleCreatTradeSubmit} >Save Changes</Button>
        {message}
      </Modal.Dialog>
 

        </div>
      )
    }


    </div>    
  )
    }

    </>
  );
};

export default ContactMessage;
