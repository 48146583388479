import { useState, useEffect, useContext } from 'react';
import {useNavigate} from "react-router-dom"
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from "../../assets/logo.png";
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import "./admin.scss"
import { makeRequest } from "../../axios";
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Wallet from './wallets'
import DepositRequestsPage from './deporequest'
import KycRequests from "./kyc.js"
import CryptoWithdrawalReceipt from "./withdrawal.js"
import Main from "./main.js"
import Manage from "./manageusers.js"
import ContactMessage from "./contact.js"
import AdminPanel from "./adminuser.js"
import UserInterestPage from "./interest.js"
import StockApprovalDashboard from "./stocksREQ.js"
// import ViewportSetter from '../userDashboard/userDashboard/ViewportSetter.js';

import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import ScatterPlotOutlinedIcon from '@mui/icons-material/ScatterPlotOutlined';
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ViewportSetter from '../userDashboard/ViewportSetter.js'
import TradesPage from './trades.js';




const Admin1 = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [message, setMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState('manage-users');
    const [selectedInvestorsComponent, setSelectedInvestorsComponent] = useState('all-trans');
    const [open, setOpen] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null)
    const [allUser, setAllUsers] = useState([])
    const [investors, setAllInvestor] = useState([])
    const [withdrawalReq, setWithdrawalReq] = useState([])
    const [kyc, setKyc] = useState([])
    const [depositReq, setDepositReq] = useState([])
    const [stockReq, setstockReq] = useState([])
    const [trades, setTrades] = useState([])
    const [userTrades, setAllTrades] = useState([])
    const [err, setErr] = useState(null);
    const [taime, setTaime] = useState();
    const [createUserTrades, setCreateUserTrades] = useState({
      amount: "",
      balance_id: "",
      stock: ""
    })
    const [isHovered, setIsHovered] = useState(false);
    const [totalBal, setTotalBal] = useState([])
    const { total_balance } = totalBal.length > 0 ? totalBal[0] : "NO";
    const [alltransactions, setAllTransactions ] = useState([])
    const [wallets, setWallets] = useState([])
    const [walletInputs, setWalletInputs] = useState({
      wallet_type: "",
      wallet_address: "",
    });

    const [modalVisible, setModalVisible] = useState(false);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const [modalContent, setModalContent] = useState("");

    

    const navigate = useNavigate()



    
   

    const handlelogout = async () => {
      try {
        await makeRequest.post("auth/logout");
        navigate("/login")
      } catch (err) {
        setErr(err.response.data);
      }
    };

    const handleWalletSubmit = async (e) => {
      e.preventDefault();
    
      try {
        const response = await makeRequest.post("admin/wallet/update", walletInputs);
        
        // Assuming the server sends a success message in the response
        setMessage(response.data.message);
      } catch (err) {
        // Handle errors, if needed
        console.error(err);
      }
    };

    const handleCreatTradeSubmit = async (e) => {
      e.preventDefault();
    
      try {
        const response = await makeRequest.post("admin/trades/new", createUserTrades);
        
        // Assuming the server sends a success message in the response
        setMessage(response.data.message);
      } catch (err) {
        // Handle errors, if needed
        console.error(err);
      }
    };




    useEffect(() => {
      makeRequest.get("admin/getusers").then((res) => {
        setAllUsers(res.data)
      } 
      )
    }, []);

    useEffect(() => {
      makeRequest.get("admin/investors").then((res) => {
        setAllInvestor(res.data)
      } 
      )
  
    }, []);    

    useEffect(() => {
      makeRequest.get("admin/withdrawal").then((res) => {
        setWithdrawalReq(res.data)
      } 
      )
    }, []);
    useEffect(() => {
      makeRequest.get("admin/kyc").then((res) => {
        setKyc(res.data)
      } 
      )
    }, []);
    useEffect(() => {
      makeRequest.get("admin/deposits").then((res) => {
        setDepositReq(res.data)
      } 
      )
  
    }, []);

    useEffect(() => {
      makeRequest.get("admin/stockreq").then((res) => {
        setstockReq(res.data)
      } 
      )
  
    }, []);

    useEffect(() => {
      makeRequest.get("admin/trades").then((res) => {
        setTrades(res.data)
      } 
      )
  
    }, []);

    useEffect(() => {
      makeRequest.get("admin/total/balance").then((res) => {
        setTotalBal(res.data)
      } 
      )
  
    }, []);

    useEffect(() => {
      makeRequest.get("admin/wallet").then((res) => {
        setWallets(res.data)
      } 
      )
  
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTaime(new Date().toLocaleTimeString()); // Reset the state
        }, 1000);
    
        return () => clearTimeout(timeout); // Cleanup to prevent memory leaks
    
    }, [taime]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
    <ViewportSetter content="width=device-width, initial-scale=1.0"/>
    <div className="app-container">
      {/* Sidebar */}
      {showSidebar && (
        <div className="sidebar">
          {/* <button className="close-btn" onClick={toggleSidebar}>
            ×
          </button> */}
          <div className="sidebar-content">
            <h2>
            <img className="logo" src={logo} alt="" style={{width:"150px", marginLeft:"-1.4rem"}}/>
            </h2>
            <h4 style={{fontSize:"18px"}}>
                {taime}
                
            </h4>
            <ul>
              <li><GridViewOutlinedIcon sx={{ fontSize: 60 }} onClick={() => setSelectedComponent('manage-users')} />  <span className="hid">DASHBOARD</span></li>
            </ul>

            <ul>
              <li><WalletOutlinedIcon sx={{ fontSize: 60 }} onClick={() => setSelectedComponent('wallet')} />  <span className="hid">WALLETS</span></li>
            </ul>

            <ul>
              <li><AddchartOutlinedIcon sx={{ fontSize: 60 }} onClick={() => setSelectedComponent('deposit')} />  <span className="hid">DEPOSIT</span></li>
            </ul>

            <ul>
              <li><ScatterPlotOutlinedIcon sx={{ fontSize: 60 }} onClick={() => setSelectedComponent('withdrawal')} />  <span className="hid">WITHDRAWAL</span></li>
            </ul>

            <ul>
              <li><AssuredWorkloadOutlinedIcon sx={{ fontSize: 60 }} onClick={() => setSelectedComponent('interest')} />  <span className="hid">INVESTORS</span></li>
            </ul>

            <ul>
              <li><FingerprintOutlinedIcon sx={{ fontSize: 60 }} onClick={() => setSelectedComponent('kyc')} />  <span className="hid">KYC</span></li>
            </ul>

            <ul>
              <li><CurrencyExchangeIcon sx={{ fontSize: 60 }} onClick={() => setSelectedComponent('contact')} />  <span className="hid">TRADE</span></li>
            </ul>

            <ul>
              <li><SupportAgentOutlinedIcon sx={{ fontSize: 60 }} onClick={() => setSelectedComponent('trades')} />  <span className="hid">TRADES</span></li>
            </ul>

            <ul>
              <li><ShowChartIcon sx={{ fontSize: 60 }}  onClick={() => setSelectedComponent('stock')} />  <span className="hid">STOCK</span></li>
            </ul>

            <ul onClick={() => setIsHovered(!isHovered)}  >
                <div >
              <li className='ova1'><Person4OutlinedIcon sx={{ fontSize: 60 }}  />  <span className="hid">ACCOUNT</span></li>
              </div>
            </ul>

          </div>
        </div>
      )}

      {/* Main Content */}
      <div className={`main-content ${showSidebar ? "with-sidebar" : ""}`}>
        <button className="toggle-btn" onClick={toggleSidebar}>
          {/* {showSidebar ? "Hide Sidebar" : "Show Sidebar"} */}
          <MenuSharpIcon/>
        </button>
       <div>
       {
          selectedComponent === "manage-users" && (
            <>
           <Main/>
            </>
          )}

       {
          selectedComponent === "wallet" && (
            <>
           <Wallet/>
            </>
          )}

        {
          selectedComponent === "deposit" && (
            <>
           <DepositRequestsPage/>
            </>
          )}


        {
          selectedComponent === "withdrawal" && (
            <>
           <CryptoWithdrawalReceipt/>
            </>
          )}



        {
          selectedComponent === "kyc" && (
            <>
           <KycRequests/>
            </>
          )}


        {
          selectedComponent === "contact" && (
            <>
           <ContactMessage/>
            </>
          )}


       {
          selectedComponent === "stock" && (
            <>
           <StockApprovalDashboard/>
            </>
          )}


         {
          selectedComponent === "trades" && (
            <>
           <TradesPage/>
            </>
          )}


        {
          selectedComponent === "user" && (
            <>
           <Manage/>
            </>
          )}

        {
          selectedComponent === "panel" && (
            <>
           <AdminPanel/>
            </>
          )}

         {
          selectedComponent === "interest" && (
            <>
           <UserInterestPage/>
            </>
          )}

        

         {isHovered ?
          <div className='ova'>
           <p style={{padding:"5px", cursor:"pointer"}} onClick={() => setSelectedComponent('panel')} >Admin</p>
          <p style={{padding:"5px", cursor:"pointer"}} onClick={() => setSelectedComponent('user')} >Users</p>
          <p style={{padding:"5px", cursor:"pointer"}} onClick={() => handlelogout()} >Log Out</p>
          </div> : null
          }
       </div>
      </div>
    </div>
    </>
  );
};

export default Admin1;
