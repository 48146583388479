import React, { useEffect, useState } from 'react';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
import AndroidIcon from '@mui/icons-material/Android';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

function UserInfoWidget() {
  const [ipAddress, setIpAddress] = useState('Fetching...');
  const [browserInfo, setBrowserInfo] = useState('');
  const [osInfo, setOsInfo] = useState('');

  // Fetch IP address from an external API
  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch(() => setIpAddress('Error fetching IP address'));
  }, []);

  // Determine the OS and Browser
  useEffect(() => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    // Simple OS detection
    if (platform.includes('Win')) setOsInfo('Windows');
    else if (platform.includes('Mac')) setOsInfo('MacOS');
    else if (platform.includes('Linux')) setOsInfo('Linux');
    else setOsInfo('Unknown OS');

    // Simple Browser detection
    if (userAgent.includes('Firefox')) setBrowserInfo('Firefox');
    else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) setBrowserInfo('Chrome');
    else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) setBrowserInfo('Safari');
    else if (userAgent.includes('Edg')) setBrowserInfo('Edge');
    else if (userAgent.includes('Opera') || userAgent.includes('OPR')) setBrowserInfo('Opera');
    else setBrowserInfo('Unknown Browser');
  }, []);

  return (
    <>
    <div style={styles.widget}>
      <p><WifiPasswordIcon style={{marginLeft:"-10px", marginRight:"10px"}}/><strong>IP Address</strong></p><span>{ipAddress}</span>
    </div>
    <div style={styles.widget}>
    <p><AndroidIcon style={{marginLeft:"-10px", marginRight:"10px"}}/><strong>Operating System</strong></p> <span>{osInfo}</span>
    </div>
    <div style={styles.widget}>
      <p> <TravelExploreIcon style={{marginLeft:"-10px", marginRight:"10px"}} /> <strong>Browser</strong> </p><span>{browserInfo}</span>
      </div>
    </>
  );
}

const styles = {
  widget: {
    padding: '10px',
    // border: '1px solid #ddd',
    borderRadius: '8px',
    maxWidth: '100%',
    display:"flex",
    justifyContent:"space-between",
    // margin: '20px auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    // textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    
  },
};

export default UserInfoWidget;
