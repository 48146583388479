import { useState,useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircleIcon, XCircleIcon, ClockIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './StockApprovalDashboard.css';
import { makeRequest } from "../../axios";



const StockApprovalDashboard = () => {
  const [stocks, setStocks] = useState([
    { 
      id: 1, 
      symbol: 'AAPL', 
      name: 'Apple Inc.', 
      quantity: 10, 
      price: 145.3, 
      total: 1453.00,
      user: 'john@example.com',
      status: 'pending',
      requestDate: '2023-07-25'
    },
    // Add more sample data...
  ]);



  const [isAdmin] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [stockReq, setstockReq] = useState([])
  const [err, setErr] = useState(null);
  const [app, setApp] = useState(false)


  useEffect(() => {
    makeRequest.get("admin/stockreq").then((res) => {
      setstockReq(res.data)
    } 
    )

  }, [app]);


  const filteredStocks = stockReq.filter(stock => {
    const matchesSearch = stock.symbol?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      stock.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      stock.user?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus === 'all' || stock.status === filterStatus;
    return matchesSearch && matchesStatus;
  });

  const handleApprove = (stock) => {
    confirmAlert({
      title: 'Confirm Approval',
      message: 'Are you sure you want to approve this transaction?',
      buttons: [
        { label: 'Yes', onClick: async () => {
          
          try {
            await makeRequest.get(`admin/stock/approve/${stock.stock_id}`);
          } catch (err) {
            setErr(err.response.data);
          }
        setApp(!app)
         }},
        { label: 'No' }
      ]
    });
  };

  const handleCancel = (id) => {
    confirmAlert({
      title: 'Confirm Cancellation',
      message: 'Are you sure you want to cancel this transaction?',
      buttons: [
        { label: 'Yes', onClick: () => updateStatus(id, 'cancelled') },
        { label: 'No' }
      ]
    });
  };

  const updateStatus = (id, status) => {
    setStocks(stockReq.map(stock => 
      stock.stock_id === id ? { ...stock, status } : stock
    ));
  };



  const StatusBadge = ({ status }) => {
    const statusConfig = {
      PENDING: { color: 'yellow', icon: ClockIcon },
      APPROVED: { color: 'green', icon: CheckCircleIcon },
      FAILED: { color: 'red', icon: XCircleIcon }
    };
    const { color, icon: Icon } = statusConfig[status];
    
    return (
      
      <span className={`${color} text-black px-3 py-1 rounded-full text-sm flex items-center gap-2 w-fit`}>
        <Icon className="w-6 h-6" style={{color:`${color}`, width:"2.5rem"}}  />
        {status.toUpperCase()}
      </span>
    );
  };

  return (
    <div className="stock-approval-container">
    <div className="dashboard-header">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="header-content">
          <h1 className="dashboard-title">
            Stock Purchase Requests
            <span className="pending-count">
              {stockReq.filter(s => s.status === 'PENDING').length}  Pending{" "}
              {stockReq.filter(s => s.status === 'APPROVED').length} approved{" "} 
              {stockReq.filter(s => s.status === 'FAILED').length} cancelled{" "} 
            </span>
          </h1>
          
          <div className="filter-controls">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search transactions..."
                className="search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MagnifyingGlassIcon className="search-icon" />
            </div>
            
            <select
              className="filter-select"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="all">All Statuses</option>
              <option value="PENDING">Pending</option>
              <option value="APPROVED">Approved</option>
              <option value="FAILED">Cancelled</option>
            </select>
          </div>
        </div>
      </motion.div>
    </div>

    <div className="stock-table">
      <div className="table-scroll">
        <table>
          <thead className="table-header">
            <tr>
              {['User', 'Stock', 'Price', 'Compunding', 'Date', 'Status', 'Actions'].map((header) => (
                <th key={header} className="table-header-cell">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          
          <tbody>
            <AnimatePresence>
              {filteredStocks.map((stock) => (
                <motion.tr
                  key={stock.stock_id }
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="table-row"
                >
                  <td className="table-cell">{stock.email}</td>
                  <td className="table-cell">
                    <div className="stock-info">
                      <img className="stock-symbol"  src={stock.logo}/>
                      <div className="stock-name">{stock.name}</div>
                    </div>
                  </td>
                  {/* <td className="table-cell">{stock.quantity}</td> */}
                  <td className="table-cell">${stock.amount.toFixed(2)}</td>
                  <td className="table-cell">{stock.compounding}</td>
                  <td className="table-cell">{stock.created_at}</td>
                  <td className="table-cell">
                    <StatusBadge status={stock.status} />
                  </td>
                  <td className="table-cell">
                    {isAdmin && stock.status === 'PENDING' && (
                      <div className="action-buttons">
                        <button
                          onClick={() => handleApprove(stock)}
                          className="action-button approve"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleCancel(stock)}
                          className="action-button cancel"
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </td>
                </motion.tr>
              ))}
            </AnimatePresence>
          </tbody>
        </table>
      </div>
      
      {filteredStocks.length === 0 && (
        <div className="empty-state">
          No matching transactions found
        </div>
      )}
    </div>
  </div>
  );
};

export default StockApprovalDashboard;