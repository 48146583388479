import React, { useState, useEffect } from "react";
import "./admin.scss";

const FlashMessage = ({ message, type, duration, onClose }) => {
  useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => {
        onClose(); // Trigger the close function after the duration
      }, duration);

      return () => clearTimeout(timer); // Clean up the timer
    }
  }, [duration, onClose]);

  return (
    <div className={`flash-message ${type}`}>
      {message}
      <button className="close-button" onClick={onClose}>
      </button>
    </div>
  );
};

export default FlashMessage;
