import React, { useState, useEffect } from "react";
import "./admin.scss";
import { makeRequest } from "../../axios";
import FlashMessage from "./flashmessage";
import Modal from "./moda2";




const DepositRequestsPage = () => {
  // Sample deposit requests
  const [depositReq, setDepositReq] = useState([])
  const [err, setErr] = useState(null);
  const [app, setApp] = useState(false);
  const [taime, setTaime] = useState();
  const [reqa, setReqa] = useState({obi:"going"});
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);





  const closeFlashMessage = () => {
    setFlashMessage(null);
  };

  const handleUserClick = (request) => {
    setSelectedUser(request);
  };

  const handleModalClose = () => {
    setSelectedUser(null);
  };



  // Handle approve and cancel actions
  const handleAction = (id, action) => {
    setDepositReq((prevRequests) =>
      prevRequests.map((request) =>
        request.id === id
          ? { ...request, trans_status: action === "approve" ? "Approved" : "Cancelled" }
          : request
      )
    );
  };


  const handleApproveDeposit = async (updatedUser) => {
    if (window.confirm(`you are about to Approve deposit for ${updatedUser.email}`)) {
    try {
    console.log(updatedUser, "user")
      await makeRequest.post(`admin/deposits/approve/${updatedUser.transaction_id}`, updatedUser);
    } catch (err) {
      setErr(err.response.data);
    }
    setFlashMessage({
      message: "deposit approved!",
      type: "success", // Can be 'success', 'error', 'info', or 'warning'
    });
  }else {
   console.log("deposit not approved")
  }
  setSelectedUser(null);
    setApp(!app);
    return
  };


  const handleCancelDeposit = async (user) => {

    if (window.confirm(`you are about to cancel deposit for ${user.email}`)) {
    try {
      await makeRequest.get(`admin/deposits/failed/${user.transaction_id}`);
    } catch (err) {
      setErr(err.response.data);
    }
  }
  else {
    alert("Request Not Cancelled.");
  }
  setSelectedUser(null);
    setApp(!app);
    return;
  };


// console.log(taime, "opk")
  useEffect(() => {
    const timeout = setTimeout(() => {
        setTaime(new Date().toLocaleTimeString()); // Reset the state
    }, 1000);

    return () => clearTimeout(timeout); // Cleanup to prevent memory leaks

}, [taime]);


  useEffect(() => {
    makeRequest.get("admin/deposits").then((res) => {
      setDepositReq(res.data)
    } 
    )

  }, [app]);


  return (
    <div className="deposit-requests-page">
      <h1 className="title">Deposit Requests</h1>
      {flashMessage && (
        <FlashMessage
          message={flashMessage.message}
          type={flashMessage.type}
          duration={1500} // Auto-hide after 3 seconds
          onClose={closeFlashMessage}
        />
      )}

      <div className="table-container">
        <table className="animated-table">
          <thead>
            <tr>
              <th>User</th>
              <th>Crypto</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Currency</th>
              <th>Received</th>
            </tr>
          </thead>
          <tbody>
            {depositReq.map((request) => (
              <tr key={request.id} className={`status-${request.trans_status.toLowerCase()}`} onClick={() => handleUserClick(request)} >
                <td >{request.name}</td>
                <td>{request.email}</td>
                <td>{(parseFloat(request.amount || 0) || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </td>
                <td>{request.trans_status}</td>
                <td>{request.method_details}</td>
                <td>{request.received}</td>
      
                {/* <td>
                  {request.trans_status === "PENDING" ? (
                    <>
                      <button
                        className="approve-button"
                        onClick={() => handleApproveDeposit(request)}
                      >
                        Approve
                      </button>
                      <button
                        className="cancel-button"
                        onClick={() => handleCancelDeposit(request)}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <span className="status-indicator">{request.trans_status}</span>
                  )}
                </td> */}
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedUser && (
        <Modal
          user={selectedUser}
          onClose={handleModalClose}
          onEdit={handleApproveDeposit}
          onDelete={handleCancelDeposit}
        />
      )}

    </div>
  );
};

export default DepositRequestsPage;
