import React, { useState, useEffect } from "react";
import "./admin.scss";
import worker from "../../assets/worker.gif"
import pig from "../../assets/save-money.gif"
import atm from "../../assets/atm.gif"
import balance from "../../assets/balance.gif"
import investor from "../../assets/investor.gif"
import PieChart from "./chart.js"
import { makeRequest } from "../../axios";
import ReqTable from "./recent.js"



const Main = () => {

const [allUsers, setAllUsers] = useState([])
const [investors, setAllInvestors] = useState([])
const [depositReq, setDepositReq] = useState([])
const [withdrawalReq, setWithdrawalReq] = useState([])
const [totalBal, setTotalBal] = useState([])
const { total_balance } = totalBal.length > 0 ? totalBal[0] : "NO";    


useEffect(() => {
    makeRequest.get("admin/getusers").then((res) => {
      setAllUsers(res.data.length)
    } 
    )
  }, []);

useEffect(() => {
    makeRequest.get("admin/withdrawal").then((res) => {
      setWithdrawalReq(res.data.length)
    } 
    )
  }, []);

useEffect(() => {
    makeRequest.get("admin/total/balance").then((res) => {
    setTotalBal(res.data)
    } 
)
    
}, []);

useEffect(() => {
    makeRequest.get("admin/investors").then((res) => {
      setAllInvestors(res.data.length)
    } 
    )

  }, []); 
  
  useEffect(() => {
    makeRequest.get("admin/deposits").then((res) => {
      setDepositReq(res.data.length)
    } 
    )

  }, []);

const balanx = parseInt(total_balance,0)
  const balanx1 = balanx.toFixed(2)
  const balanx2 = balanx1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  return (
    <>
    <div className="responsive-container">
       <div className="box">
         <div style={{display:"flex", justifyContent:"center"}}>
        <img src={worker} style={{width:"80px"}}/>
        </div>
         <div style={{marginTop:"1rem", color:"black"}}>
        <div className="boxa">{allUsers}</div>
        <div className="boxi">USERS</div>
        </div>
        </div>
      <div className="box">
      <div style={{display:"flex", justifyContent:"center"}}>
        <img src={pig} style={{width:"80px"}}/>
        </div>
        <div style={{marginTop:"1rem", color:"black"}}>
        <div className="boxa">{depositReq}</div>
        <div className="boxi">DEPOSIT</div>
        </div>
      </div>
      <div className="box">
      <div style={{display:"flex", justifyContent:"center"}}>
        <img src={atm} style={{width:"80px"}}/>
        </div>
        <div style={{marginTop:"1rem", color:"black"}}>
        <div className="boxa">{withdrawalReq}</div>
        <div className="boxi">WITHDRAWAL</div>
        </div>
      </div>
      <div className="box">
      <div style={{display:"flex", justifyContent:"center"}}>
        <img src={balance} style={{width:"80px"}}/>
        </div>
        <div style={{marginTop:"1rem", color:"black"}}>
        <div className="boxa">{balanx2}</div>
        <div className="boxi">TOTAL BALANCE</div>
        </div>
      </div>
      
      <div className="box">
      <div style={{display:"flex", justifyContent:"center"}}>
        <img src={investor} style={{width:"80px"}}/>
        </div>
        <div style={{marginTop:"1rem", color:"black"}}>
        <div className="boxa">{investors}</div>
        <div className="boxi">INVESTORS</div>
        </div>
      </div>
    </div>
    <div>
    
    </div>
    <ReqTable/>
    {/* <PieChart/> */}
    </>
  );
};

export default Main;
